
import { defineComponent, onMounted, ref,computed } from 'vue'
import router from "@/router";
import { useStore } from "vuex";
import {  Actions } from "@/store/enums/StoreEnums";
import { loaderLogo } from "@/core/helpers/config";

// import Auths from '@/core/services/Callable/Auths';

export default defineComponent({
    setup() {

        const store = useStore();

        onMounted(async () => {
            const routeParams = router.currentRoute.value.params;

            const payload = {
                'switch_to':routeParams?.switchTo,
                'refresh_token':routeParams?.refreshToken
            }
   
           const newToken = await store.dispatch(Actions.SWITCH_APP, payload);
           if(newToken){
               setTimeout(() => {
                   router.push({ name: "dashboard" }).then(() => { return router.go(0) })
                }, 1000)
            }
       })

        const loading = ref(true)

        const tableData = [
            {
                text: 'Loading',
            },
        ]

        const switchTo = computed(() => {
            const routeParams = router.currentRoute.value.params;
            return routeParams?.switchTo;
        });

        return {
        loaderLogo,
        switchTo
        }
    },
})
